import Environment from './interfaces/environment.model';

export const environment = new Environment({
  name: 'uat',
  production: false,
  serverHost: `${location.origin}`,
  serverURL:  `${location.origin}`,
  taggingSiteId: '74798210',
  //admin login okta config:
  okta: {
    issuer:      'https://uat-acmeremarketing.oktapreview.com/oauth2/ausb3u8p2rSCIS8J11d7',
    clientId:    '0oab3u32klNu9IN0T1d7',
    redirectUri: `${location.origin}/login/admin`,
    baseUrl:     'https://login.uat.enterpriseremarketing.com',
    idp:         '0oab3untx9qiJSInu1d7',
  },
  samlUrls: {
    external: 'https://login.uat.enterpriseremarketing.com/home/uat-remarketingplatform_auctionplatform20_1/0oa12a23w2o4xxp610h8/aln12a2apoaZzX2UE0h8',
    internal: 'https://login.uat.enterpriseremarketing.com/home/uat-remarketingplatform_internalauctionplatform20_1/0oa12a1e2gplcJw870h8/aln12a1rpvxb0Kfcn0h8'
  },
  websocket: {
    isEnabled: true,
    isEventLoggingEnabled: true,
    isLoggingEnabled: true,
    reconnectDelay:    100,
    reconnectInterval: 600000,
  },
  featureFlags: {
    buySearchBulkSale: true,
  },
  isLoggingServiceEnabled: false,
  launchDarklyKey: '60acff8ad7bc970e4fcbd2cc',
  securityProfileUrl: 'https://uat.securedealer.ally.com/ui/manage/profile',
  apigeeApiKey: '0VYpF2h1IgoossJDNG0g9tkorIRHM1TW', //UAT
  apigeeBaseUrl: 'https://secure-qa1.ally.com/acs/auto-remarketing', //qa is mapped to UAT
  apigeeUATAccessTokenPath: '/auth-auto-remarketing-auth-qa-r-mammoth-25df5c', //changes for UAT for releases. Temporary 
  apigeeUATOESPath: '/organization-entity-auto-remarketing-organization-entity-qa-r-mammoth-25df5c', //changes for UAT for releases. Temporary 
  apigeeAuthorizationKey: 'Basic MFZZcEYyaDFJZ29vc3NKRE5HMGc5dGtvcklSSE0xVFc6dml0bzZHMHVvYlhiODNkdg=='  //UAT base64 encoded apikey:secret
});
