import { Component, HostListener, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import {ConfigurationState, TransportationFeeTier} from 'remarketing-angular-library';
import { ModalComponent } from 'remarketing-ui-components-lib';
import { TransportationFeeTierRange } from '../../../models/transportation-fee-tier-range.model';
import { Store } from '@ngxs/store';
import {get as _get} from 'lodash';
import { TranslocoService } from '@ngneat/transloco';
import { takeUntil } from 'rxjs/operators';
import { SaComponent, AnalyticsService, CurrencyFormatService } from 'smartauction-frontend-micro-library';

const PARENT_PAGE = {
  search: 'SEARCH',
  bidlist: 'BID_LIST',
  'direct-sales': 'DIRECT_SALES',
  'auction-summary': 'AUCTION_SUMMARY',
  inventory: 'INVENTORY',
  vehicles: 'VDP'
};

@Component({
  selector: 'app-transportation-fee-modal',
  templateUrl: './transportation-fee-modal.component.html',
  styleUrls: ['./transportation-fee-modal.component.scss']
})
export class TransportationFeeModalComponent extends SaComponent implements OnInit{

  @Input() transportationFeeTiers: TransportationFeeTier[];
  @ViewChild(ModalComponent) modal: ModalComponent;
  @Input() modalId = 'Transportation-Fee-Schedule';
  transportationFeeTierRanges: TransportationFeeTierRange[];
  @Input() parentPageName: string;
  labelMiles: string;
  labelOver: string;
  distanceUnit: string;

  @HostListener('document:keyup.escape', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.modal.modal.isOpen) {
      this.modal.close();
    }
  }

  constructor(
    private readonly _analyticsService: AnalyticsService,
    private readonly _store: Store,
    private readonly translocoService: TranslocoService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly currencyFormatService: CurrencyFormatService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getTranslations();
  }

  show(event: Event){
    /* Ignoring @Input tenantName to safeguard against someone else's error that will result in the missing @Input*/
    this._analyticsService.track('modalView',`${this.getTenantName()}:Buy:${this.getParentPage()}:TransportationFeeDetails`);
    event.preventDefault();
    if(this.transportationFeeTiers.length > 0){
      this.convertMaxMileageToRange(this.transportationFeeTiers);
    }
    this.modal.show();
    this.changeDetector.markForCheck();
    setTimeout(() => {
      this.focusHeader();
    }, 1000);
  }

  focusHeader() {
    const modalHeader = document.getElementById('transportation-fee-modal-title');

    if (modalHeader) {
      modalHeader.focus();
    }
  }

  closeModal(){
    this.modal.close();
  }

  convertMaxMileageToRange(tiers: TransportationFeeTier[]) : void {
    const convertedTiers = [];
    let lastMaxMileage = -1;
    let lastTier: TransportationFeeTier;
    const internationalNumberFormat = new Intl.NumberFormat('en-US');

    //Formats table row text using ranges
    if (tiers && tiers.length > 0) {
      lastTier = tiers.pop();
      tiers.forEach(tier => {
        convertedTiers.push({
          mileageRange: `${internationalNumberFormat.format(lastMaxMileage + 1)} - ${internationalNumberFormat.format(tier.maxMileage)} ${this.distanceUnit}`,
          transportationFee: tier.amount
        } as TransportationFeeTierRange);
        lastMaxMileage = tier.maxMileage;
      });

      //Formats last table row text
      const lastRowMinimumMileage = this.lastMaxMileageNotChanged(lastMaxMileage) ? lastMaxMileage + 1 : lastMaxMileage;
      convertedTiers.push({
        mileageRange: `${this.labelOver} ${internationalNumberFormat.format(lastRowMinimumMileage)} ${this.distanceUnit}`,
        transportationFee: lastTier.amount
      } as TransportationFeeTierRange);
      this.transportationFeeTierRanges = convertedTiers;
      tiers.push(lastTier);
    } else {
      this.transportationFeeTierRanges = undefined;
    }
  }

  lastMaxMileageNotChanged(lastMaxMileage: number): boolean {
    return lastMaxMileage === -1;
  }

  private getTenantName(): string {
    return _get(this._store.selectSnapshot(ConfigurationState.configuration), 'tenantName', '');
  }

  private getParentPage(): string {
    const path = this.getLocationPathname();
    let toReturn = 'UNKNOWN';
    const triggers = Object.keys(PARENT_PAGE);
    for (const trigger of triggers) {
      if (this.parentPageName) {
        toReturn = this.parentPageName;
      } else if (path.includes(trigger)) {
        toReturn = PARENT_PAGE[trigger];
        break;
      }
    }
    return toReturn;
  }

  private getLocationPathname() {
    return window.location.pathname;
  }

  getTranslations(){
    const keyParams = {
      'shared': {},
      'common': {}
    };

    this.translocoService.selectTranslateObject(keyParams).pipe(takeUntil(this.destroyed$)).subscribe((translate: any[]) => {
      this.labelMiles = translate[0].transportationFeeModal.labelMiles;
      this.labelOver = translate[0].transportationFeeModal.labelOver;
      this.distanceUnit = translate[1].distanceUnit;

    }, error => {
      console.log(error);
    });
  }

  formatCurrency(currency) {
    return this.currencyFormatService.formatCurrency(currency, false).replaceAll('-', '')
  }
}
