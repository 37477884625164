<ng-container *transloco="let convert;">
<app-modal [modalId]="modalId">

    <ng-container class="modal_container">
      <header class="modal__header">
        <h1 class="modal__title" id="transportation-fee-modal-title"  [attr.id]="modalId + '-title'" tabindex="-1">
          <strong>{{ convert('shared.transportationFeeModal.textTransportaionFee') }}</strong>
        </h1>
      </header>
      <p>{{ convert('shared.transportationFeeModal.summaryTransportionFee') }}</p>

      <section class="transportation-fee-table">
        <app-table>
          <table
            cdk-table
            [dataSource]="transportationFeeTierRanges"
            [attr.aria-label]="convert('shared.transportationFeeModal.ariaLabelTransportationFeeTier')"
          >
            <ng-container cdkColumnDef="distanceRange" class="left-column">
              <th cdk-header-cell id="distance-range-column-header" *cdkHeaderCellDef>{{ convert('shared.transportationFeeModal.headingDistanceRange') }}</th>
              <td cdk-cell *cdkCellDef="let tier">{{tier.mileageRange}}</td>
            </ng-container>
            <ng-container cdkColumnDef="transportationFee" class="right-column">
              <th cdk-header-cell id="transportation-fee-column-header" class="text-end" *cdkHeaderCellDef>{{ convert('shared.transportationFeeModal.headingTransportationFee') }}</th>
              <td cdk-cell class="text-end" *cdkCellDef="let tier">
                {{ formatCurrency(tier.transportationFee)  }}
              </td>
            </ng-container>
            <tr cdk-header-row class="table-row" *cdkHeaderRowDef="['distanceRange', 'transportationFee']"></tr>
            <tr cdk-row class="table-row" *cdkRowDef="let row; columns: ['distanceRange', 'transportationFee']"></tr>
          </table>
        </app-table>
      </section>

      <div class="modal__action">
        <button
          class="btn btn-primary"
          (click)="closeModal()"
          data-track-elem="button"
          data-track-name="Cancel"
          data-track-trigger="close"
          id="close_button"
          #cancelSave
        >
        {{ convert('common.btnClose') }}
        </button>
      </div>

      <div class="modal-close-container">
        <button
          class="modal__close"
          [attr.aria-label]="convert('common.modalClose')"
          (click)="closeModal()"
          data-track-elem="icon"
          data-track-name="Close"
          data-track-trigger="close"
          id="do_close_x_button"
          #closeButton
        >
          <i class="allycon-glyph-close text-primary" aria-hidden="true"></i>
        </button>
      </div>
    </ng-container>

</app-modal>
</ng-container>
